<template>
    <v-data-table
        :headers="headers"
        :items="paginationItems.data"
        :items-per-page="paginationParams.per_page"
        :page="paginationParams.page"
        :server-items-length="paginationItems.total"
        :loading="loading"
        sort-by="id"
        class="border"
        @update:page="changePage"
        @update:items-per-page="changeItemsPerPage"
        :footer-props="footerProps"
        @update:sort-by="sortBy"
        @update:sort-desc="sortDesc"

    >
        <template v-slot:top>
            <v-toolbar flat color="white">
                <v-dialog fullscreen v-model="dialog" max-width="800px">
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" dark class="mb-2" v-on="on">{{ trans('buttons.landing.newItem') }}</v-btn>
                    </template>
                    <landing-form
                        v-if="dialogLoaded"
                        :itemId="editedItem"
                        v-on:saved="onSave"
                        v-on:updated="onUpdate"
                        v-on:close="close"
                    />
                </v-dialog>
            </v-toolbar>
            <v-container>
                <v-row>
                    <v-col style="min-width: 200px;">
                        <v-text-field
                                v-model="filters.id"
                                :label="trans('fields.common.id')"
                                clearable
                        ></v-text-field>
                    </v-col>
                    <v-col style="min-width: 200px;">
                        <v-text-field
                                v-model="filters.label"
                                :label="trans('fields.common.label')"
                                clearable
                        ></v-text-field>
                    </v-col>
                    <v-col style="min-width: 200px;">
                        <user-autocomplete
                                v-model="filters.user_id"
                                :label="trans('fields.common.user')"
                                clearable
                        ></user-autocomplete>
                    </v-col>
                    <v-col style="min-width: 200px;">
                        <v-select
                                :items="options.landingDesigns"
                                :label="trans('fields.common.landingDesign')"
                                :multiple="true"
                                v-model="filters.design_id"
                                clearable
                        ></v-select>
                    </v-col>
                    <v-col style="min-width: 200px;">
                        <v-select
                                :items="options.countries"
                                :label="trans('fields.common.countries')"
                                :multiple="true"
                                v-model="filters.country_id"
                                clearable
                        ></v-select>
                    </v-col>
                    <v-col style="min-width: 200px;">
                        <v-select
                                :items="options.languages"
                                :label="trans('fields.common.languages')"
                                :multiple="true"
                                v-model="filters.language_id"
                                clearable
                        ></v-select>
                    </v-col>
                    <v-col style="min-width: 200px;">
                        <v-text-field
                                v-model="filters.domain_name"
                                :label="trans('fields.common.domain')"
                                clearable
                        ></v-text-field>
                    </v-col>

                </v-row>
                <v-row>
                    <v-btn
                            class="ma-2"
                            color="primary"
                            outlined
                            @click="applyFilters"
                    >{{ trans('buttons.common.apply') }}</v-btn>
                    <v-btn
                            class="ma-2"
                            color="orange"
                            outlined
                            @click="clearFilters"
                    >{{ trans('buttons.common.clear') }}</v-btn>
                </v-row>
            </v-container>
        </template>
        <template v-slot:item.actions="{ item }">
            <a :href="route('admin.landings.preview', item.id)" target="_blank"><v-icon small class="mr-2">mdi-eye</v-icon></a>
            <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
        <template v-slot:item.created_at="{ item }">
            {{ item.created_at | mysqlDate }}
        </template>
        <template v-slot:item.language="{ item }">
            {{ options.languagesKeyed ? options.languagesKeyed[item.language_id] : '' }}
        </template>
        <template v-slot:item.country="{ item }">
            {{ options.countriesKeyed ? options.countriesKeyed[item.country_id] : '' }}
        </template>
        <template v-slot:item.user="{ item }">
            <user-link :user="item.user"></user-link>
        </template>
        <template v-slot:item.offer_appearances_count="{ item }">
            <v-chip
                class="ma-2"
                :color="(parseInt(item.offer_appearances_count) ? parseInt(item.offer_appearances_filled_count) / parseInt(item.offer_appearances_count) * 100 : 0) !== 100 ? 'red' : 'green'"
                text-color="white"
            >
                <v-progress-circular
                    :value="Math.floor(parseInt(item.offer_appearances_count) ? parseInt(item.offer_appearances_filled_count) / parseInt(item.offer_appearances_count) * 100 : 0)"
                    color="white"
                    class="mr-4 small-caption"
                    size="24"
                >{{ Math.floor(parseInt(item.offer_appearances_count) ? parseInt(item.offer_appearances_filled_count) / parseInt(item.offer_appearances_count) * 100 : 0) }}</v-progress-circular>
                {{ item.offer_appearances_filled_count }} / {{ item.offer_appearances_count }}
            </v-chip>

        </template>

    </v-data-table>
</template>

<script>
    import tableWithPagination from "@/mixins/tableWithPagination";
    import tableFilters from "@/mixins/tableFilters";
    import UserLink from "@/components/admin/user/UserLink";
    import UserAutocomplete from "@/components/admin/user/UserAutocomplete";

    export default {
        name: "LandingTable",
        components: {
            UserAutocomplete,
            UserLink,
            LandingForm: () => import('@/components/admin/landing/LandingForm'),
        },
        mixins: [tableWithPagination, tableFilters],
        data() {
            return {
                dialog: false,
                dialogLoaded: false,
                landings: {},
                headers: [
                    { text: "id", sortable: false, value: "id" },
                    { text: this.trans("fields.common.label"), value: "label", sortable: false },
                    { text: this.trans("fields.common.domain"), value: "domain.name", sortable: false },
                    { text: this.trans("fields.common.offers"), value: "offer_appearances_count", sortable: false },
                    { text: this.trans("fields.common.language"), value: "language", sortable: false },
                    { text: this.trans("fields.common.country"), value: "country", sortable: false },
                    { text: this.trans("fields.common.user"), value: "user", sortable: false },
                    { text: this.trans("fields.common.landingDesign"), value: "design.label", sortable: false },
                    { text: this.trans("fields.common.actions"), value: "actions", sortable: false },
                ],
                paginationParams: {
                    page: 1,
                    per_page: 10,
                },
                url: this.route('admin.landings.index'),

                filters: {
                    id: null,
                    label: null,
                    user_id: null,
                    language_id: null,
                    country_id: null,
                    design_id: null,
                    domain_name: null,
                },

                editedItem: null,
            }
        },
        created() {
            this.editedItem = null;
            this.initialize();
            this.loadOptions({languages: true, countries: true, landingDesigns: true});
        },
        watch: {
            dialog(val) {
                if (val){
                    this.dialogLoaded = true
                } else {
                    this.close();
                }
            }
        },
        methods: {
            onSave(){
                this.initialize();
            },
            onUpdate(){
                this.close();
                this.initialize();
            },
            editItem(item) {
                this.editedItem = item.id;
                this.dialog = true;
                this.dialogLoaded = true;
            },
            deleteItem(item) {
                confirm("Are you sure you want to delete this item?") &&
                console.log(['deleted', item]);
            },

            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedItem = null;
                    this.dialogLoaded = false;
                }, 300);
            },


        },
        computed: {
            paginationItems: {
                get(){
                    return this.landings;
                },
                set(v){
                    this.landings = v;
                }
            },
        }
    }
</script>

<style scoped>
    .small-caption {
        font-size: 9px;
        margin-left: -6px;
    }
</style>
